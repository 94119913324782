<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Row align="top">
      <Col span="24">
        <Button
          type="warning"
          class="ml2"
          v-if="$canAction(['Sys_Cache_Edit'])"
          @click="$importCache('AllMemberLevel', $data)"
          >更新缓存<i>{{ cacheUpdatedTime }}</i></Button
        >
      </Col>
    </Row>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      @on-sort-change="sortChange"
      :data="resObj"
      highlight-row
      size="small"
    >
      <template slot="LV" slot-scope="{ row }">
        <span>{{ "LV" + row.ID }}</span>
      </template>
      <template slot="Image" slot-scope="{ row }">
        <Avatar
          :src="$setImagePrefix(row.Logo)"
          style="min-width: 31px; height: 31px; margin-top: 3px"
        />
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="success"
          v-if="$canAction(['Member_Level_Edit'])"
          @click="handleOpenEdit(row)"
          >编辑</Button
        >
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page :total="resObj.length" size="small" :page-size="100" show-total />
      </Col>
    </Row>
    <edit-level-modal
      ref="editLevelModal"
      @on-save="onUpdated"
    ></edit-level-modal>
  </Layout>
</template>

<script>
import api from "@/api";
import layout from "@/components/layout/index.vue";
import editLevelModal from "./components/member-level-edit.vue";
export default {
  name: "MemberLevel",
  data() {
    return {
      tableLoading: false,
      tableHeight: 0,
      resObj: [],
      cacheUpdatedTime: "",
      columns: [
        {
          title: "等级ID",
          key: "ID",
          minWidth: 90,

          align: "center",
          sortable: "true",
        },
        {
          title: "等级名称",
          key: "LevelName",
          minWidth: 100,

          align: "center",
        },
        {
          title: "等级图标",
          key: "Logo",
          minWidth: 90,
          slot: "Image",

          align: "center",
        },
        {
          title: "升级所需数量",
          key: "UpGradeNum",
          minWidth: 90,

          align: "center",
          sortable: "true",
        },
        {
          title: "备注",
          key: "Remark",
          minWidth: 100,

          align: "left",
          sortable: "custom",
        },
        {
          title: "操作",
          key: "Action",

          slot: "Action",
          minWidth: 60,
          maxWidth: 60,
          fixed: "right",
          align: "center",
        },
      ],
    };
  },
  methods: {
    async LoadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetAllMemberLevel();
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
      }
    },
    onUpdated() {
      const _this = this;
      _this.$delayLoad(() => {
        _this.LoadList();
      });
    },
    handleOpenEdit(row) {
      this.$refs.editLevelModal.$refs.form.resetFields();
      this.$refs.editLevelModal.obj = JSON.parse(JSON.stringify(row));
      this.$refs.editLevelModal.isShow = true;
    },
    handleSearch() {
      this.params.Page = 1;
      this.LoadList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.LoadList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.TempDate = "";
      this.params.StartDate = null;
      this.params.EndDate = null;
      this.LoadList();
    },
  },
  created() {
    this.LoadList();
    this.$getCacheUpdatedTime("AllMemberLevel", this);
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.LoadList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.LoadList();
        this.$getCacheUpdatedTime("AllMemberLevel", this);
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.LoadList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  computed: {},
  components: { editLevelModal, layout },
};
</script>
